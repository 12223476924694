import React, { useContext, useState } from "react";
import { multiStepContext } from "../StepContext";
import "../Styles/Authentication.css";
import line from "../assets/line.png";
import Axios from "axios";

const Authentication = () => {
  const [inputValue, setInputValue] = useState("");
  const { setStep, userData, setUserData } = useContext(multiStepContext);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(true);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [agreeToPopupTerms, setAgreeToPopupTerms] = useState(false);

  const apiURL = process.env.REACT_APP_API_URL;

  const checkNumber = async () => {
    if (inputValue.length !== 13) {
      alert("กรุณากรอกหมายเลขบัตรประชาชนให้ครบ 13 หลัก");
    } else {
      const response = await Axios.get(`${apiURL}checkid/${inputValue}`);

      if (response.data.result === "Found") {
        setUserData({ ...userData, citizenId: inputValue });
        setStep(16);
      } else if (response.data.result === "nameExisted") {
        setStep(22);
      } else {
        setStep(18);
      }
    }
  };

  const handleCheckboxChange = () => {
    if (!agreeTerms) {
      setShowTermsPopup(true);
    } else {
      setAgreeTerms(false);
    }
  };

  const handlePopupAccept = () => {
    if (agreeToPopupTerms) {
      setAgreeTerms(true);
      setShowTermsPopup(false);
      setAgreeToPopupTerms(false)
    } else {
      alert("กรุณายอมรับเงื่อนไขก่อนดำเนินการต่อ");
    }
  };

  const handlePopupCancel = () => {
    setAgreeTerms(false);
    setShowTermsPopup(false);
    setAgreeToPopupTerms(false)
  };

  const handlePopupCheckboxChange = () => {
    setAgreeToPopupTerms(!agreeToPopupTerms);
  };

  const closeInfoPopup = () => {
    setShowInfoPopup(false);
  };

  const handleInputChange = (e) => {
    let numericValue = e.target.value.replace(/\D/g, "").trim();
    if (numericValue.length > 13) {
      numericValue = numericValue.slice(0, 13);
    }
    setInputValue(numericValue);
  };

  return (
    <div className="authen-outer-div">
      {showInfoPopup && (
        <div className="popup-overlay">
          <div className="popup-window">
            <div className="popup-header">
              <span className="popup-title">ขั้นตอนการลงทะเบียน</span>
            </div>
            <div className="popup-content">
              <ul className="popup-list">
                <li>เตรียมบัตรประชาชาน / บัตรสวัสดิการ / บัตรประจําตัวคนพิการ ของท่าน</li>
                <img src={line} className="line-between-point" alt="line" />
                <li>เตรียมข้อมูลผู้ดูแลของท่าน (ถ้ามี)</li>
                <img src={line} className="line-between-point" alt="line" />
                <li>
                  หากท่านต้องการใช้หมายเลขเบอร์โทรเดิมของท่านต่อ
                  กรุณาทำการย้ายเครือข่ายไปยังเครือข่าย Infinite โครงข่ายโทรคมนาคมแห่งชาติ ก่อนการลงทะเบียน
                </li>
              </ul>
              <div className="popup-buttons-info">
                <button className="popup-accept" style={{width: '90px'}} onClick={closeInfoPopup}>
                  ปิด
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showTermsPopup && (
        <div className="popup-overlay">
          <div className="popup-window-step">
            <div className="popup-header">
              <span className="popup-title">ข้อกำหนดและเงื่อนไขการให้บริการ</span>
            </div>
            <div className="popup-content-term">
              <div className="popup-term-text">
                <ol className="popup-term-list">
                  <li>
                    <strong>การลงทะเบียนและการเปิดใช้งานซิม:</strong> ผู้ใช้บริการต้องลงทะเบียนซิมโทรศัพท์เคลื่อนที่ตามข้อกำหนดของผู้ให้บริการ
                  </li>
                  <li>
                    <strong>การใช้งานซิม:</strong> ซิมโทรศัพท์เคลื่อนที่สามารถใช้งานสำหรับการรับข้อความ, การใช้งานอินเทอร์เน็ต หรือบริการอื่นๆ ผู้ใช้จะต้องปฏิบัติตามเงื่อนไขการใช้งานและข้อจำกัดต่างๆ
                  </li>
                  <li>
                    <strong>การเติมเงินและการคิดค่าบริการ:</strong> ซิมแบบเติมเงิน สามารถเติมเงิน สำหรับการใช้งานการโทรและส่งข้อความเพิ่มเติมได้ ซึ่งอยู่นอกเหนือจากโปรโมชั่นที่โครงการฯ กำหนด โดยคิดอัตราค่าบริการนาทีละ 0.25สตางค์ โดยหักจากเงินในกระเป๋า
                  </li>
                  <li>
                    <strong>ระยะเวลาการใช้งาน:</strong> ซิมจะมีระยะเวลาการใช้งาน สิ้นสุด วันที่ 31 กรกฏาคม 2568
                  </li>
                  <li>
                    <strong>ความรับผิดชอบของผู้ใช้:</strong> ผู้ใช้ต้องรับผิดชอบต่อการใช้งานซิมตามกฎหมายและนโยบายของผู้ให้บริการ รวมถึงไม่ใช้ซิมในทางที่ผิดกฎหมาย หรือเพื่อทำกิจกรรมที่ละเมิดสิทธิของบุคคลอื่น และไม่นำไปใช้งาน BitTorrent การแชร์เน็ตผ่านHotspot แบบสาธารณะ การดาวน์โหลด และ/หรือ อัฟโหลดไฟล์ขนาดใหญ่ หรือใช้ในเชิงพาณิชย์ หรือมีการใช้ซึ่งส่งผลกระทบหรือก่อให้เกิดความไม่เป็นธรรมต่อผู้ใช้บริการรายอื่น และห้ามนำไปจำหน่ายต่อ
                  </li>
                  <li>
                    <strong>การยกเลิกหรือการเปลี่ยนแปลงเงื่อนไข:</strong> ผู้ให้บริการมีสิทธิในการเปลี่ยนแปลงเงื่อนไขการให้บริการซิม โดยจะต้องแจ้งให้ผู้ใช้บริการและสำนักงาน กสทช ทราบล่วงหน้า
                  </li>
                  <li>
                    <strong>การปกป้องข้อมูลส่วนบุคคล:</strong> ผู้ให้บริการจะต้องรักษาความปลอดภัยของข้อมูลส่วนบุคคลของผู้ใช้ และจะไม่เปิดเผยข้อมูลดังกล่าวแก่บุคคลภายนอก ยกเว้นในกรณีที่มีกฎหมายบังคับ
                  </li>
                </ol>
              </div>
              <label className="popup-term-checkbox">
                <input
                  type="checkbox"
                  checked={agreeToPopupTerms}
                  onChange={handlePopupCheckboxChange}
                />{" "}
                ข้าพเจ้ายอมรับข้อกำหนด และเงื่อนไขการให้บริการข้างต้น
              </label>
              <div className="popup-buttons">
                <button className="popup-cancel" onClick={handlePopupCancel}>
                  ยกเลิก
                </button>
                <button className="popup-accept" onClick={handlePopupAccept}>
                  ตกลง
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="authen-container">
        <div className="authen-info">
          ตรวจสอบสิทธิของท่าน
          <br />
          <span style={{ fontSize: "16" }}>Check your eligibility</span>
        </div>
        <div className="authen-input">
          <div className="authen-id">
            <div className="authen-id-info">
              หมายเลขบัตรประจําตัวประชาชน / บัตรสวัสดิการ / บัตรประจําตัวคนพิการ
            </div>
            <input
              className="id-text"
              type="text"
              placeholder="โปรดกรอกข้อมูล"
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="authen-next-bt">
          <label className="policy-con">
            <input
              type="checkbox"
              checked={agreeTerms}
              onChange={handleCheckboxChange}
            />
            <div className="policy-info">
              ข้าพเจ้ายินยอมให้ใช้ข้อมูลส่วนตัวสําหรับการตรวจสอบการได้รับสิทธิเข้าร่วมโครงการ
              <br />
              <span className="policy">
                I hereby consent to my personal data being processed for the
                purpose of considering my eligibility
              </span>
            </div>
          </label>
          <div className="bt-position">
            <button className="authen-back-bt" onClick={() => setStep(1)}>
              ย้อนกลับ
            </button>
            <button
              onClick={checkNumber}
              disabled={!agreeTerms}
              className="authen-next-button"
            >
              ต่อไป
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Authentication;