import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/RegisterCheckPage.css'
import lastStep from '../assets/last-step.png';
import Axios from 'axios';

const RegisterCheckPage = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const { setStep, userData, setUserData, submitData } = useContext(multiStepContext);
    const [options, setOptions] = useState([]);
    const branchId = parseInt(userData.branchId);

    const accBackStep = () => {
        if (userData.phoneNumberOption === "ไม่ใช่") {
            setStep(7);
        } else {
            if (userData.simMethod === "mail") {
                if (userData.addressOption === "default") {
                    setStep(30)
                } else if (userData.addressOption === "new") {
                    setStep(30)
                }
            } else if (userData.simMethod === "branch") {
                setStep(12);
            } else if (userData.simMethod === "disabledBranch") {
                setStep(32);
            }
        }
    };

    const getOperator = async () => {
        try {
            const response = await Axios.get(apiURL + 'getAllOperator');
            setOptions(response.data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    useEffect(() => {
        getOperator();
    }, [userData]);

    const renderBrandOption = () => {
        const selectedOption = options.find(option => option.id === parseInt(userData.brandOption));
        return selectedOption ? selectedOption.company_name : 'Unknown';
    };

    const handleLocation = () => {
        if (userData.phoneNumberOption === "ใช่") {
            if (userData.simMethod === 'mail') {
                if (userData.addressOption === "default") {
                    const fullAddress = `${userData.addressName || ''} ${userData.subDistrict || ''} ${userData.district || ''} ${userData.province || ''} ${userData.zipCode || ''}`.trim();
                    return (
                        <div className='acc-service'>
                            ที่อยู่การจัดส่ง
                            <input type="text" className='reg-acc-text' value={fullAddress} readOnly></input>
                        </div>
                    );
                } else if (userData.addressOption === "new") {
                    const fullAddress = `${userData.addressNameNew || ''} ${userData.subDistrictNew || ''} ${userData.districtNew || ''} ${userData.provinceNew || ''} ${userData.zipCodeNew || ''}`.trim();
                    return (
                        <div className='acc-service'>
                            ที่อยู่การจัดส่ง
                            <input type="text" className='reg-acc-text' value={fullAddress} readOnly></input>
                        </div>
                    );
                }
            } else if (userData.simMethod === "branch") {
                return (
                    <div className='acc-service'>
                        รับซิมการ์ดที่ศูนย์บริการ
                        <input type="text" className='reg-acc-text' value={userData.branchName} readOnly></input>
                    </div>
                );
            }
        } else {
            return null;
        }
    };


    const renderPhoneNumber = () => {
        if (userData["phoneNumberOption"] === "ไม่ใช่") {
            return userData["phoneNumber"]
        }
        else {
            return "ท่านประสงค์รับซิมการ์ดใหม่"
        }
    }

    const sendDataToAPI = async () => {
        try {
            const response = await Axios.post(apiURL + `saveDisabledData`, {
                nationalid: userData['citizenId'],
                firstname: userData['firstname'],
                lastname: userData['lastname'],
                operator: parseInt(userData.brandOption),
                status: "submit",
                gFirstname: userData["legalFirstname"],
                gLastname: userData["legalLastname"],
                gNationalId: userData["legalId"],
                typeId: userData.selectedOption,
                phoneNumber: userData["phoneNumber"],
                purpose: userData["phoneNumberOption"],
                dType: userData.DType
            });
            updateStatus(userData.citizenId);
            setStep(8);
        } catch (error) {
            console.error('Error sending data to API:', error);
        }
    };

    const updateStatus = async (statusId) => {
        if (userData.simMethod === 'mail') {
            if (userData.addressOption === "default") {
                await Axios.put(apiURL + `changeStatusAddress/${statusId}`, {
                    addressName: userData.addressName,
                    subDistrict: userData.subDistrict,
                    district: userData.district,
                    province: userData.province,
                    zipCode: userData.zipCode,
                    email: userData.email,
                    lineId: userData.lineId,
                    phoneNumber: userData.phoneNumberAddress
                });
            } else if (userData.addressOption === "new") {
                await Axios.put(apiURL + `changeStatusAddress/${statusId}`, {
                    addressName: userData.addressNameNew,
                    subDistrict: userData.subDistrictNew,
                    district: userData.districtNew,
                    province: userData.provinceNew,
                    zipCode: userData.zipCodeNew,
                    email: userData.emailNew,
                    lineId: userData.lineIdNew,
                    phoneNumber: userData.phoneNumberAddressNew
                });
            }
        } else if (userData.simMethod === 'branch') {
            const response = await Axios.put(apiURL + `changeStatus/${statusId}`, {
                branchId: branchId
            });
        } else if (userData.simMethod === 'disabledBranch') {
            const response = await Axios.put(apiURL + `changeStatus/${statusId}`, {
                disabledBranchId: userData.disabledBranchId
            });
        }
    }

    return (
        <>
            {
                <div className="reg-acc-body">
                    <div className="reg-acc-container">
                        <div className="reg-acc-area-con">
                            <div className="reg-acc-process-bar">
                                <img src={lastStep} className="last-step-bar" alt="OTP Process Bar" />
                            </div>
                            <div className="reg-acc-info">
                                โปรดตรวจสอบความถูกต้อง
                                <br />
                                <span style={{ fontSize: '16' }}>Please check the accuracy</span>
                            </div>
                            <div className="reg-acc-service-detail">
                                <div className="acc-service">
                                    ชื่อ
                                    <input type="text" className='reg-acc-text' value={userData.firstname} readOnly></input>
                                </div>
                                <div className="reg-acc-phone">
                                    นามสกุล
                                    <input type="text" className='reg-acc-text' value={userData.lastname} readOnly></input>
                                </div>
                            </div>
                            <div className='reg-acc-package'>
                                หมายเลขบัตรประชาชน / บัตรสวัสดิการ / บัตรประจําตัวคนพิการ
                                <input type="text" className='reg-status-id' value={userData['citizenId']} readOnly></input>
                            </div>
                            <div className="reg-acc-service-detail">
                                <div className="acc-service">
                                    เครือข่าย
                                    <input type="text" className='reg-acc-text' value={renderBrandOption()} readOnly></input>
                                </div>
                                <div className="reg-acc-phone">
                                    หมายเลขโทรศัพท์
                                    <input type="text" className='reg-acc-text' value={renderPhoneNumber()} readOnly></input>
                                </div>
                            </div>
                            <div className="reg-acc-service-detail">
                                {handleLocation()}
                                {userData.simMethod === 'disabledBranch' && (
                                    <div className="acc-service">
                                        รับซิมการ์ดที่ศูนย์บริการคนพิการ
                                        <input type="text" className='reg-acc-text' value={'ศูนย์บริการคนพิการจังหวัด ' + userData.disabledBranchName} readOnly></input>
                                    </div>
                                )}
                            </div>
                            <div className="reg-acc-bt">
                                <button onClick={accBackStep} className="reg-acc-back-bt">ย้อนกลับ</button>
                                <button onClick={() => { sendDataToAPI(); }} className="reg-acc-next-bt">ยืนยันข้อมูล</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default RegisterCheckPage;