import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/SelectTypePage.css';
import TypeProcessBar from '../assets/reg-process-bar.png';
import Axios from 'axios';

const SelectTypePage = () => {
  const { setStep, userData, setUserData } = useContext(multiStepContext);
  const [isChecked, setIsChecked] = useState(false);
  const [type, setType] = useState(null);
  const apiURL = process.env.REACT_APP_API_URL;
 
  useEffect(() => {
    const getTypeData = async () => {
      try {
        const response = await Axios.get(apiURL + `getPersonType/${userData.citizenId}`);
        let deformId = parseInt(response.data.DEFORMID);
      
        // if ([4, 5, 6, 7].includes(deformId)) {
        //   deformId = 4;
        // }

        setUserData({ ...userData, selectedOption: deformId });
        } catch (error) {
        console.error('API call failed:', error);
      }
    };

    getTypeData();
  }, []);

  useEffect(() => {
    if (userData.selectedOption) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [userData]);

  const handleOptionChange = (option) => {
    setUserData({ ...userData, "selectedOption": option });
    setIsChecked(true);
  };

  const handleNextStep = () => {
    if (!isChecked) {
      alert("Please select at least one option.");
      return;
    }
    setStep(4);
  };

  return (
    <div className="type-outer">
      <div className="type-container">
        <div className="type-process-bar">
          <img src={TypeProcessBar} className="type-pro-bar" alt="Registration Process Bar" />
        </div>
        <div className="type-info">
          โปรดเลือกตัวเลือกที่สอดคล้องกับท่านมากที่สุด
          <br />
          <span style={{ fontSize: '16' }}>Please indicate the most suitable option</span>
        </div>
        <div className="option-container">
          <div className="type-cb-op">
            <label>
              <input
                type="checkbox"
                checked={userData['selectedOption'] === 1}
                disabled={userData['selectedOption'] !== 1}
                onChange={() => handleOptionChange(1)}
              />
              พิการทางการเห็น
            </label>
          </div>
          <div className="type-cb-op">
            <label>
              <input
                type="checkbox"
                checked={userData['selectedOption'] === 2}
                disabled={userData['selectedOption'] !== 2}
                onChange={() => handleOptionChange(2)}
              />
              พิการทางการได้ยินหรือสื่อความหมาย
            </label>
          </div>
          <div className="type-cb-op">
            <label>
              <input
                type="checkbox"
                checked={userData['selectedOption'] === 3}
                disabled={userData['selectedOption'] !== 3}
                onChange={() => handleOptionChange(3)}
              />
              พิการทางการเคลื่อนไหวหรือทางร่างกาย
            </label>
          </div>
          <div className="type-cb-op">
            <label>
              <input
                type="checkbox"
                checked={
                  userData['selectedOption'] === 4
                }
                disabled={userData['selectedOption'] !== 4}
                onChange={() => handleOptionChange(4)}
              />
              <span className="label-text">
                พิการด้านจิตใจหรือพฤติกรรม
              </span>
            </label>
          </div>
          <div className="type-cb-op">
            <label>
              <input
                type="checkbox"
                checked={
                  userData['selectedOption'] === 5
                }
                disabled={userData['selectedOption'] !== 5}
                onChange={() => handleOptionChange(5)}
              />
              <span className="label-text">
                พิการทางสติปัญญา
              </span>
            </label>
          </div>
          <div className="type-cb-op">
            <label>
              <input
                type="checkbox"
                checked={
                  userData['selectedOption'] === 6
                }
                disabled={userData['selectedOption'] !== 6}
                onChange={() => handleOptionChange(6)}
              />
              <span className="label-text">
                พิการทางการเรียนรู้
              </span>
            </label>
          </div>
          <div className="type-cb-op">
            <label>
              <input
                type="checkbox"
                checked={
                  userData['selectedOption'] === 7
                }
                disabled={userData['selectedOption'] !== 7}
                onChange={() => handleOptionChange(7)}
              />
              <span className="label-text">
                หรือพิการทางออทิสติก
              </span>
            </label>
          </div>
        </div>
        <div className="type-bt-container">
          <button onClick={handleNextStep} className="type-form-bt">ต่อไป</button>
        </div>
      </div>
    </div>
  );
};

export default SelectTypePage;
