import React, { useContext, useState, useEffect } from 'react';
import { CreateInput } from 'thai-address-autocomplete-react';
import { multiStepContext } from '../StepContext';
import '../Styles/SimMethod.css';
import '../Styles/SimAddressDefault.css';
import lineQR from '../assets/qrcode_line.png';
import otpProcessBar from '../assets/otp-process-bar.png';
import Axios from 'axios';

const InputThaiAddress = CreateInput();

const SimAddressDefault = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const { setStep, userData, setUserData } = useContext(multiStepContext);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [addressOption, setAddressOption] = useState("");
    const [district, setDistrict] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [province, setProvince] = useState("");
    const [subDistrict, setSubDistrict] = useState("");
    const [addressName, setAddressName] = useState(userData.addressName || "");
    const [email, setEmail] = useState(userData.email || "");
    const [lineId, setLineId] = useState(userData.lineId || "");
    const [phoneNumberAddress, setPhoneNumber] = useState(userData.phoneNumberAddress || "");
    const [addressNameNew, setAddressNameNew] = useState(userData.addressNameNew || "");
    const [emailNew, setEmailNew] = useState(userData.emailNew || "");
    const [lineIdNew, setLineIdNew] = useState(userData.lineIdNew || "");
    const [phoneNumberAddressNew, setPhoneNumberNew] = useState(userData.phoneNumberAddressNew || "");

    const [address, setAddress] = useState({
        district: userData.subDistrictNew || '',
        amphoe: userData.districtNew || '',
        province: userData.provinceNew || '',
        zipcode: userData.zipCodeNew || '',
    });

    const handleChange = (scope) => (value) => {
        setAddress((oldAddr) => ({
            ...oldAddr,
            [scope]: value,
        }));
    };

    const handleSelect = (address) => {
        setAddress(address);
    };

    useEffect(() => {
        const getAddressData = async () => {
            try {
                const response = await Axios.get(apiURL + `getPersonAddress/${userData.citizenId}`);
                setDistrict(response.data.MAIMAD_CURR_DISTRICT_NAME);
                setZipCode(response.data.MAIMAD_CURR_POST_CODE);
                setProvince(response.data.MAIMAD_CURR_PROVINCE_NAME);
                setSubDistrict(response.data.MAIMAD_CURR_SUBDISTRICT_NAME);
            } catch (error) {
                console.error('API call failed:', error);
            }
        };

        getAddressData();
    }, []);

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const closeInfoPopup = () => {
        setIsPopupVisible(false);
    };

    const handleOptionChange = (e) => {
        setAddressOption(e.target.value);
    };

    const handleNextStep = () => {
        if (addressOption === "default") {
            if (addressName.trim() === "") {
                alert("กรุณากรอกข้อมูลที่อยู่ให้ครบถ้วน");
                return;
            }
            setUserData({
                ...userData,
                addressName,
                district,
                zipCode,
                province,
                subDistrict,
                email,
                lineId,
                phoneNumberAddress,
                addressOption
            });
            setStep(17);
        } else if (addressOption === "new") {
            if (
                addressNameNew.trim() === "" ||
                address.district.trim() === "" ||
                address.amphoe.trim() === "" ||
                address.province.trim() === "" ||
                address.zipcode.trim() === ""
            ) {
                alert("กรุณากรอกข้อมูลที่อยู่ให้ครบถ้วน");
                return;
            }
            setUserData({
                ...userData,
                addressNameNew,
                districtNew: address.amphoe,
                subDistrictNew: address.district,
                provinceNew: address.province,
                zipCodeNew: address.zipcode,
                emailNew,
                lineIdNew,
                phoneNumberAddressNew,
                addressOption
            })
            setStep(17);
        } else {
            alert("กรุณาเลือกที่อยู่การจัดส่ง (Please select an address for receiving the SIM card).");
        }
    };

    return (
        <>
            <div className="sim-body">
                <div className="brand-process-bar">
                    <img src={otpProcessBar} className="brand-pro-bar" alt="OTP Process Bar" />
                </div>
                <div className="sim-container">
                    <div className="sim-area-con">
                        <div className="sim-info">
                            ที่อยู่การจัดส่งซิมการ์ด
                            <br />
                            <span style={{ fontSize: '16' }}>SIM card delivery address.</span>
                        </div>
                        <div className="sim-fill-area">
                            <div className="radio-container" style={{ marginBottom: '15px' }}>
                                <label className="radio-label">
                                    <input
                                        type="checkbox"
                                        name="simAddress"
                                        value="new"
                                        checked={addressOption === "new"}
                                        onChange={handleOptionChange}
                                    />
                                    จัดส่งที่อยู่ใหม่
                                </label>
                                {addressOption === "new" && (
                                    <>
                                        <div className='acc-package'>
                                            <span>ที่อยู่/บ้านเลขที่/อาคาร/ถนน/ซอย<span style={{ color: 'red', marginLeft: '4px' }}>*</span></span>
                                            <input
                                                type="text"
                                                className='address-name'
                                                value={addressNameNew}
                                                onChange={(e) => setAddressNameNew(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="acc-service-detail">
                                            <div className="acc-service">
                                                <span>ตำบล<span style={{ color: 'red', marginLeft: '4px' }}>*</span></span>
                                                <InputThaiAddress.District
                                                    type="text"
                                                    className='address-sub-district'
                                                    value={address['district']}
                                                    onChange={handleChange('district')}
                                                    onSelect={handleSelect}
                                                    required
                                                />
                                            </div>
                                            <div className="acc-phone">
                                                <span>อำเภอ<span style={{ color: 'red', marginLeft: '4px' }}>*</span></span>
                                                <InputThaiAddress.Amphoe
                                                    type="text"
                                                    className='address-district'
                                                    value={address['amphoe']}
                                                    onChange={handleChange('amphoe')}
                                                    onSelect={handleSelect}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="acc-service-detail">
                                            <div className="acc-phone">
                                                <span>จังหวัด<span style={{ color: 'red', marginLeft: '4px' }}>*</span></span>
                                                <InputThaiAddress.Province
                                                    type="text"
                                                    className='address-province'
                                                    value={address['province']}
                                                    onChange={handleChange('province')}
                                                    onSelect={handleSelect}
                                                    required
                                                />
                                            </div>
                                            <div className="acc-service">
                                                <span>รหัสไปรษณีย์<span style={{ color: 'red', marginLeft: '4px' }}>*</span></span>
                                                <InputThaiAddress.Zipcode
                                                    type="text"
                                                    className='address-zipcode'
                                                    value={address['zipcode']}
                                                    onChange={handleChange('zipcode')}
                                                    onSelect={handleSelect}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="acc-service-detail">
                                            <div className="acc-phone">
                                                เบอร์โทรติดต่อ (ถ้ามี)
                                                <input
                                                    type="text"
                                                    className="address-phone-number"
                                                    value={phoneNumberAddressNew}
                                                    onChange={(e) => {
                                                        const onlyDigits = e.target.value.replace(/\D/g, '');
                                                        if (onlyDigits.length <= 10) {
                                                            setPhoneNumberNew(onlyDigits);
                                                        }
                                                    }}
                                                    maxLength="10"
                                                />
                                            </div>
                                            <div className="acc-phone">
                                                อีเมล (ถ้ามี)
                                                <input
                                                    type="text"
                                                    className='address-email'
                                                    value={emailNew}
                                                    onChange={(e) => setEmailNew(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="acc-service-detail">
                                            <div className="acc-half">
                                                Line ID (ถ้ามี)
                                                <input
                                                    type="text"
                                                    className='address-line-id'
                                                    value={lineIdNew}
                                                    onChange={(e) => setLineIdNew(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="radio-container">
                                <label className="radio-label">
                                    <input
                                        type="checkbox"
                                        name="simAddress"
                                        value="default"
                                        checked={addressOption === "default"}
                                        onChange={handleOptionChange}
                                    />
                                    จัดส่งที่อยู่ตามบัตรประชาชน
                                </label>
                                {addressOption === "default" && (
                                    <>
                                        <div className='acc-package'>
                                            <span>ที่อยู่/บ้านเลขที่/อาคาร/ถนน/ซอย<span style={{ color: 'red', marginLeft: '4px' }}>*</span></span>
                                            <input
                                                type="text"
                                                className='address-name'
                                                disabled={addressOption !== "default"}
                                                value={addressName}
                                                onChange={(e) => setAddressName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="acc-service-detail">
                                            <div className="acc-service">
                                                <span>ตำบล<span style={{ color: 'red', marginLeft: '4px' }}>*</span></span>
                                                <input type="text" className='address-sub-district' value={subDistrict} disabled />
                                            </div>
                                            <div className="acc-phone">
                                                <span>อำเภอ<span style={{ color: 'red', marginLeft: '4px' }}>*</span></span>
                                                <input type="text" className='address-district' value={district} disabled />
                                            </div>
                                        </div>
                                        <div className="acc-service-detail">
                                            <div className="acc-phone">
                                                <span>จังหวัด<span style={{ color: 'red', marginLeft: '4px' }}>*</span></span>
                                                <input type="text" className='address-province' value={province} disabled />
                                            </div>
                                            <div className="acc-service">
                                                <span>รหัสไปรษณีย์<span style={{ color: 'red', marginLeft: '4px' }}>*</span></span>
                                                <input type="text" className='address-zipcode' value={zipCode} disabled />
                                            </div>
                                        </div>
                                        <div className="acc-service-detail">
                                            <div className="acc-phone">
                                                เบอร์โทรติดต่อ (ถ้ามี)
                                                <input
                                                    type="text"
                                                    className="address-phone-number"
                                                    disabled={addressOption !== "default"}
                                                    value={phoneNumberAddress}
                                                    onChange={(e) => {
                                                        const onlyDigits = e.target.value.replace(/\D/g, '');
                                                        if (onlyDigits.length <= 10) {
                                                            setPhoneNumber(onlyDigits);
                                                        }
                                                    }}
                                                    maxLength="10"
                                                />
                                            </div>
                                            <div className="acc-phone">
                                                อีเมล (ถ้ามี)
                                                <input
                                                    type="text"
                                                    className='address-email'
                                                    disabled={addressOption !== "default"}
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="acc-service-detail">
                                            <div className="acc-half">
                                                Line ID (ถ้ามี)
                                                <input
                                                    type="text"
                                                    className='address-line-id'
                                                    disabled={addressOption !== "default"}
                                                    value={lineId}
                                                    onChange={(e) => setLineId(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='sim-submit-area'>
                                <label>
                                    <a href="#" className="to-service" onClick={togglePopup}>ติดต่อเจ้าหน้าที่</a>
                                </label>
                                <div className='sim-button'>
                                    <button onClick={() => setStep(29)} className="form-back-bt">
                                        ย้อนกลับ
                                    </button>
                                    <button onClick={handleNextStep} className="authen-next-button">
                                        ต่อไป
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isPopupVisible && (
                <div className="popup-overlay">
                    <div className="popup-window">
                        <div className="popup-header">
                            <span className="popup-title">ติดต่อเจ้าหน้าที่</span>
                            <button className="popup-close-btn" onClick={closeInfoPopup}>
                                &times;
                            </button>
                        </div>
                        <div className="popup-content" style={{ paddingTop: '0px' }}>
                            <ul className='popup-content-text'>
                                <li>
                                    ช่องทางการติดต่อผู้ให้บริการ Infinite sim บนโครงข่ายโทรคมนาคมแห่งชาติ โครงการอินเตอร์เน็ตฟรีสำหรับคนพิการและนักเรียนยากจน
                                    <span style={{ marginLeft: '5px' }}>
                                        <a href="https://lin.ee/EgE32QQ" target="_blank" rel="noopener noreferrer" className="popup-link">
                                            https://lin.ee/EgE32QQ
                                        </a>
                                    </span>
                                </li>
                                <li>Line : @netfree_infinite</li>
                            </ul>
                            <img src={lineQR} className='popup-line-qr' alt="QR Code" />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SimAddressDefault;