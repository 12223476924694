import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/SimMethod.css'
import lineQR from '../assets/qrcode_line.png'
import otpProcessBar from '../assets/otp-process-bar.png';
import brandPackage from '../assets/brand-package.png';
import Axios from 'axios';

const SimMethod = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const { setStep, userData, finalData, setUserData } = useContext(multiStepContext);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const closeInfoPopup = () => {
        setIsPopupVisible(false);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleNextStep = () => {
        setUserData(prevData => ({ ...prevData, simMethod: selectedOption }));
        if (selectedOption === "mail") {
            setStep(30);
        } else if (selectedOption === "branch") {
            setStep(12);
        } else if (selectedOption === "disabledBranch") {
            setStep(32);
        } else {
            alert("กรุณาเลือกช่องทางการรับซิมการ์ด (Please select a way to receive the SIM card)");
        }
    };

    return (
        <>
            <div className="sim-body">
                <div className="brand-process-bar">
                    <img src={otpProcessBar} className="brand-pro-bar" alt="OTP Process Bar" />
                </div>
                <div className="sim-container">
                    <div className="sim-area-con">
                        <div className="sim-info">
                            ช่องทางรับซิมการ์ด
                            <br />
                            <span style={{ fontSize: '16' }}>Ways to receive a SIM card.</span>
                        </div>
                        <div className="sim-fill-area">
                            <div className="radio-container">
                                <label className="radio-label">
                                    <input
                                        type="checkbox"
                                        name="simMethod"
                                        value="mail"
                                        checked={selectedOption === "mail"}
                                        onChange={handleOptionChange}
                                    />
                                    จัดส่งซิมการ์ดทางไปรษณีย์
                                </label>
                            </div>
                            <div className="radio-container">
                                <label className="radio-label">
                                    <input
                                        type="checkbox"
                                        name="simMethod"
                                        value="branch"
                                        checked={selectedOption === "branch"}
                                        onChange={handleOptionChange}
                                    />
                                    รับที่ไปรษณีย์ใกล้บ้าน
                                </label>
                            </div>
                            {/* <div className="radio-container">
                                <label className="radio-label">
                                    <input
                                        type="checkbox"
                                        name="simMethod"
                                        value="disabledBranch"
                                        checked={selectedOption === "disabledBranch"}
                                        onChange={handleOptionChange}
                                    />
                                    รับที่ศูนย์บริการคนพิการประจำจังหวัด
                                </label>
                            </div> */}
                            <div className='sim-submit-area'>
                                <label>
                                    <a href="#" className="to-service" onClick={togglePopup}>ติดต่อเจ้าหน้าที่</a>
                                </label>
                                <div className='sim-button'>
                                    <button onClick={() => setStep(7)} className="form-back-bt">
                                        ย้อนกลับ
                                    </button>
                                    <button onClick={handleNextStep} className="authen-next-button">
                                        ต่อไป
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isPopupVisible && (
                <div className="popup-overlay">
                    <div className="popup-window">
                        <div className="popup-header">
                            <span className="popup-title">ติดต่อเจ้าหน้าที่</span>
                            <button className="popup-close-btn" onClick={closeInfoPopup}>
                                &times;
                            </button>
                        </div>
                        <div className="popup-content" style={{ paddingTop: '0px' }}>
                            <ul className='popup-content-text'>
                                <li>
                                    ช่องทางการติดต่อผู้ให้บริการ Infinite sim บนโครงข่ายโทรคมนาคมแห่งชาติ โครงการอินเตอร์เน็ตฟรีสำหรับคนพิการและนักเรียนยากจน
                                    <span style={{ marginLeft: '5px' }}>
                                        <a href="https://lin.ee/EgE32QQ" target="_blank" rel="noopener noreferrer" className="popup-link">
                                            https://lin.ee/EgE32QQ
                                        </a>
                                    </span>
                                </li>
                                <li>Line : @netfree_infinite</li>
                            </ul>
                            <img src={lineQR} className='popup-line-qr' />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default SimMethod;
