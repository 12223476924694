import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/TermPage.css';
import Axios from 'axios';

const TermPage = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const {setStep, userData, setUserData} = useContext(multiStepContext);
    const statusId = userData.statusId;
    
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [type, setType] = useState("");

    const getTerm = async (statusId) => {
        const response = await Axios.get(apiURL+`getOperationRecord/${statusId}`);
        setType(response.data);
      }

    const packageDetail = type && type.OpInfo[0].packages.packageDetail
    
    useEffect(() => {
        if (statusId) {
            getTerm(statusId);     
        }
      }, [statusId]);

    const handleCheckboxChange = () => {
        setAgreeTerms(!agreeTerms);
    };

    const handleNextButtonClick = () => {
        if (agreeTerms) {
            setStep(11);
        } else {
            alert("Please agree to the terms.");
        }
    };

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
        setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <>
                <div className="term-body">
                    <div className="term-container">
                        <div className="term-area-con">
                            <div className="term-info">
                                    ขอบคุณสําหรับการลงทะเบียน
                                    <br/>
                                    <span style={{ fontSize: '16' }}>Thank You For Your Compliance</span> 
                            </div>
                            <div className={`term-scroll ${windowHeight < 650 ? 'not-term-scroll' : 'term-scroll'}`}>
                            {
                                packageDetail.split('\n').map((item, i) => {
                                    return <p key={i}>{item}</p>;
                                    })
                            }
                            </div>
                            <div className='term-next-bt'>
                                <label>
                                    <input type="checkbox" checked={agreeTerms} onChange={handleCheckboxChange}/> ฉันยอมรับข้อตกลง (Agree to the Terms and Conditions)
                                </label>
                                <button onClick={handleNextButtonClick} disabled={!agreeTerms} className="term-userInfo-button">
                                    ต่อไป
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        </> 
    );
}

export default TermPage;