import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/NotApprovedPage.css';
import NotapproveIcon from '../assets/fail-icon.png';

const NotApprovedPage = () => {
  const {setStep, userData, setUserData} = useContext(multiStepContext);
  
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="not-container">
        <div className='not-info'>
            การลงทะเบียนของท่านไม่ผ่านข้อกำหนดโครงการ
            <br/>
            <span style={{ fontSize: '16' }}>You are not Eligible</span>
        </div>
        <div className='not-info-detail'>
            เหตุผล: {userData.notPassReason}
        </div>
        <div className="not-img-container">
          <img src={NotapproveIcon} className={`not-img ${windowHeight < 650 ? 'hidden-notFound' : 'not-img'}`}></img>
        </div>
        <div className="not-text">
        </div>
        <div className="not-register-con">
            <button onClick={() => setStep(1)} className="not-register-bt">กลับสู่หน้าหลัก</button>
        </div>
    </div>
  )
}

export default NotApprovedPage;