import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import legalProcessBar from '../assets/legal-process-bar.png';
import '../Styles/LegalPage.css';
import Axios from 'axios';

const LegalPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const {setStep, userData, setUserData} = useContext(multiStepContext);
  const nationalId = userData.citizenId;
  const [data, setData] = useState("");
  const [type, setType] = useState("");
  const [client, setClient] = useState("");

  const handlePageStep = async () => {

      const resultGuardian = await checkGuardian(nationalId, userData);
      const resultDisabled = await checkClient(nationalId);
      const resultType = await checkType(nationalId);
      

      if (resultGuardian.result === "Match" && resultType.result === "Match"  && resultDisabled.result === "Match") {
        setUserData({ ...userData, DType: resultType.DType });
        setStep(6);
    } else {
        setStep(15);
    }
  }

  const checkGuardian = async (nationalId, userData) => {
    try{
      const response = await Axios.post(apiURL+`checkGuardian/${nationalId}`,{
        CURATOR_FIRST_NAME: userData.legalFirstname,
        CURATOR_LAST_NAME: userData.legalLastname,
        CURATOR_PERSON_CODE: userData.legalId
      });
      return response.data;
    }catch (error) {
      console.error('Error sending data to API:', error);
    }
  }

  const checkClient = async (nationalId) => {
    try{
      const response = await Axios.post(apiURL+`checkInfo/${nationalId}`,{
        MAIMAD_FIRST_NAME_THAI: userData.firstname,
        MAIMAD_LAST_NAME_THAI: userData.lastname
      });
      return response.data;
    }catch (error) {
      console.error('Error sending data to API:', error);
    }
  }
 
  const checkType = async (nationalId) => {
    const response = await Axios.post(apiURL+`checkPersonDT/${nationalId}`,{
      typeSelected : userData.selectedOption
    });
    return response.data;
  }

  const handleLegalFirstNameChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setUserData({ ...userData, legalFirstname: trimmedValue });
  }
  
  const handleLegalLastNameChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setUserData({ ...userData, legalLastname: trimmedValue });
  }

  const handleLegalIdChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, '').trim(); 
    if (numericValue.length > 13) {
      numericValue = numericValue.slice(0, 13);
    }
    setUserData({ ...userData, legalId: numericValue });
}

  return (
    <div className="legal-container">
        <div className="legal-process-bar"> 
              <img src={legalProcessBar} className="legal-pro-bar"></img>
        </div>
        <div className="legal-info">
              สําหรับกรอกข้อมูลผู้ดูแลตามบัตรคนพิการ
              <br/>
              <span style={{ fontSize: '16' }}>For legal / lawful guardian information</span> 
        </div>
        <div className='legal-input'>
           <div className="legal-name">
              <div className="legal-fname">
                   ชื่อ
                   <input
                      className="legal-text"
                      type='text'
                      placeholder='โปรดกรอกข้อมูล'
                      value={userData['legalFirstname']}
                      onChange={handleLegalFirstNameChange}
                    />
              </div>
              <div className="legal-lname">
                นามสกุล
                    <input
                      className="legal-text"
                      type='text'
                      placeholder='โปรดกรอกข้อมูล'
                      value={userData['legalLastname']}
                      onChange={handleLegalLastNameChange}
                    />
              </div>
            </div>
          <div className="legal-id">
              หมายเลขบัตรประชาชน
                    <input
                      className="legal-idNum"
                      type='text'
                      placeholder='โปรดกรอกข้อมูล'
                      value={userData['legalId']}
                      onChange={handleLegalIdChange}
                    />
          </div>
        </div>
        <div className="form-bt">
            <button onClick={() => setStep(4)} className="form-back-bt">ย้อนกลับ</button>
            <button onClick={ handlePageStep } className="form-next-bt">ต่อไป</button>
        </div>
    </div>
  );
};

export default LegalPage;