import React, { useContext, useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import { Icon, DivIcon } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import '../Styles/Map.css';
import { multiStepContext } from '../StepContext';
import Axios from 'axios';

const Map = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const { userData } = useContext(multiStepContext);
  const statusId = userData.citizenId;
  const loId = userData.locationId;
  const loName = userData.locationName;
  const [markers, setMarkers] = useState([]);
  const [coor, setCoor] = useState({
    lat: 13,
    lng: 104
  });
  const mapRef = useRef(null);

  useEffect(() => {
    if (userData.locationLat !== undefined && userData.locationLong !== undefined) {
      setCoor({
        lat: userData.locationLat,
        lng: userData.locationLong
      });
    }
  }, [userData.locationLat, userData.locationLong]);

  useEffect(() => {
    if (statusId && loId && loName) {
      getServiceAndNum(statusId, loId, loName);
    }
  }, [statusId, loId, loName]);

  const [position, setPosition] = useState(null);

  const HandleClickMap = () => {
    const map = useMapEvents({
      click(e) {
        setPosition(e.latlng);
        map.flyTo({ lat: coor.lat, lng: coor.lng });
      }
    });
    return null;
  };

  useEffect(() => {
    const flyToInitialPosition = () => {
      if (mapRef.current && coor.lat && coor.lng) {
        mapRef.current.flyTo([coor.lat, coor.lng]);
      }
    };

    flyToInitialPosition();
  }, [coor.lat, coor.lng]);

  const getServiceAndNum = async (statusId, loId, loName) => {
    try {
      const response = await Axios.get(apiURL + `getBranch/${userData.brandOption}/${loName}`);

      const branches = response.data.OpBranch;

      const newMarkers = branches.map(branch => ({
        geocode: [parseFloat(branch.latBranch), parseFloat(branch.LongBranch)],
        popUp: {
          name: branch.name,
          fullAddress: branch.fullAddress,
          phoneNumber: branch.phoneNumber,
        }
      }));

      setMarkers(newMarkers);
    } catch (error) {
      console.error('Error fetching service and number:', error);
    }
  };

  const customIcon = new Icon({
    iconUrl: require("../assets/placeholder.png"),
    iconSize: [38, 38]
  });

  const createCustomClusterIcon = (cluster) => {
    return new DivIcon({
      html: `<div class="cluster-icon">${cluster.getChildCount()}</div>`,
      className: "custom-marker-cluster"
    });
  };

  return (
    <div>
      <MapContainer
        style={{ width: '100%', height: '350px' }}
        center={[13.743393, 100.509374]}
        zoom={10}
        scrollWheelZoom={false}
        ref={mapRef}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <HandleClickMap />
        <MarkerClusterGroup
          chunkedLoading
          iconCreateFunction={createCustomClusterIcon}
        >
          {markers.map((marker, index) => (
            <Marker key={index} position={marker.geocode} icon={customIcon}>
              <Popup>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <strong style={{ fontSize: '18px', marginBottom: '10px' }}>{marker.popUp.name}</strong>
                  <div style={{ fontSize: '14px', marginBottom: '10px' }}>
                    <strong>ที่อยู่:</strong> {marker.popUp.fullAddress}
                  </div>
                  <div style={{ fontSize: '14px', marginBottom: '10px' }}>
                    <strong>เบอร์โทร:</strong> {marker.popUp.phoneNumber}
                  </div>
                </div>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
}

export default Map;