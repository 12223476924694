import React, { useContext, useState, useEffect } from 'react';
import Select from 'react-select';
import { multiStepContext } from '../StepContext';
import Map from '../tools/Map';
import '../Styles/MapPage.css';
import 'leaflet/dist/leaflet.css';
import otpProcessBar from '../assets/otp-process-bar.png';
import Axios from 'axios';

const MapPage = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const { setStep, userData, setUserData } = useContext(multiStepContext);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [location, setLocation] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");
    const [branch, setBranch] = useState([]);
    const [selectedSubLocation, setSelectedSubLocation] = useState("");
    const [nextDisabled, setNextDisabled] = useState(true); // State to manage the disabled state of the next button

    const getLocation = async () => {
        try {
            const response = await Axios.get(apiURL + `getLocation`);
            setLocation(response.data.branch);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    }

    const getSubLocation = async (selectedLocation) => {
        try {
            const response = await Axios.get(apiURL + `getBranch/${userData.brandOption}/${selectedLocation.name}`);
            const branchData = response.data.OpBranch;
            setBranch(branchData);
        } catch (error) {
            console.error('Error fetching sub-locations:', error);
        }
    }

    const handleLocationChange = () => {
        const exactMatchLocation = location.find(loc => loc.zipCode === searchInput);
    
        if (exactMatchLocation) {
            const selectedId = exactMatchLocation.id;
            const selectedName = exactMatchLocation.zipCode;
            const lattitude = exactMatchLocation.lat;
            const longtitude = exactMatchLocation.Long;
    
            setUserData({
                ...userData,
                locationId: selectedId,
                locationName: selectedName,
                locationLat: lattitude,
                locationLong: longtitude
            });
            setSelectedLocation({
                id: selectedId,
                name: selectedName,
                lat: lattitude,
                lon: longtitude
            });
            setSelectedSubLocation("");
            setBranch([]);
            setNextDisabled(true);
        } else {
            alert("ไม่พบสาขาที่ท่านกรอกรหัสไปรษณี");
            setSelectedLocation("");
            setSelectedSubLocation("");
            setBranch([]);
            setNextDisabled(true);
        }
    }

    const handleSubLocationChange = (event) => {
        const selectedSubName = event.target.value;
        const selectedSubId = event.target.selectedOptions[0].getAttribute('data-id'); // If branch ID is included in data-id attribute
        setSelectedSubLocation(selectedSubName);
        setUserData({ ...userData, branchName: selectedSubName, branchId: selectedSubId });
        setNextDisabled(false);
    }

    useEffect(() => {
        getLocation();
    }, []);

    useEffect(() => {
        if (selectedLocation.id && selectedLocation.name) {
            getSubLocation(selectedLocation);
        }
    }, [selectedLocation]);

    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: '#000000aa',
            boxShadow: state.isFocused ? 'none' : 'none',
            '&:hover': { borderColor: 'black' },
            borderRadius: '2px',
            fontSize: '14px'
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '14px'
        }),
    };

    return (
        <div className={`location-body ${windowHeight < 650 ? 'small-body' : 'location-body'}`}>
            <div className="brand-process-bar">
                <img src={otpProcessBar} className="brand-pro-bar" alt="OTP Process Bar" />
            </div>
            <div className="location-container">
                <div className="location-area-con">
                    <div className="location-info">
                        รับซิมการ์ดที่ไปรษณีย์ใกล้บ้านของท่าน
                        <br />
                        <span style={{ fontSize: '16' }}>You may choose the nearest service center to pick up your new SIM card.</span>
                    </div>
                    <div className="location-fill-area">
                        <div className='location-id-con'>
                            ศูนย์บริการ
                            <div className='location-serach-box'>
                                <input
                                    id="dropdown"
                                    type="text"
                                    className="location-choice-search"
                                    placeholder="รหัสไปรษณีย์ที่อยู่ปัจจุบัน"
                                    // options={location.map(loc => ({
                                    //     value: loc.id,
                                    //     label: loc.zipCode,
                                    //     lat: loc.lat,
                                    //     lon: loc.Long
                                    // }))}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                    value={searchInput}
                                />
                                <button onClick={handleLocationChange} className="map-search-bt">ค้นหา</button>
                            </div>
                        </div>
                        <div style={{ marginTop: '-10px' }}>
                            {selectedLocation && (
                                <div className='sub-location-con'>
                                    <select id="sub-dropdown" className="location-choice" style={{ paddingLeft: '10px' }} onChange={handleSubLocationChange} value={selectedSubLocation}>
                                        <option value="" disabled>โปรดเลือกสาขา</option>
                                        {branch.map(sub => (
                                            <option key={sub.id} value={sub.name} data-id={sub.id}>{sub.name}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                        <div className={`map ${windowHeight < 650 ? 'map-small' : 'map'}`}>
                            <Map />
                        </div>
                        <div className={`map-bt ${windowHeight < 650 ? 'map-bt-small' : 'map-bt'}`}>
                            <button onClick={() => setStep(29)} className="map-back-bt">ย้อนกลับ</button>
                            <button onClick={() => setStep(17)} className={`map-next-bt ${nextDisabled ? 'disabled' : ''}`} disabled={nextDisabled}>ยืนยันข้อมูล</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MapPage;