import React,{Component} from 'react';
import '../Styles/Sidebar.css'
import nbtcLogo from '../assets/nbtc-logo.png';
import SidebarFooter from '../tools/SidebarFooter';
import depLogo from '../assets/dep_logo.gif'
import infiniteLogo from '../assets/INFINITE_LOGO_FIT.png'
import SidebarImg from '../tools/SidebarImg';

const Sidebar = () => {
    return(
        <div>
            <div className="sidebar-container">
                <div className="sidebar-flex">
                    <div className="logo-brand">
                        <img src={nbtcLogo} className='sidebar-logo'></img>
                        <img src={depLogo} className='sidebar-logo' style={{ marginLeft: '20px' }}></img>
                        <img src={infiniteLogo} className='sidebar-logo' style={{ marginLeft: '20px' }}></img>
                        {/* <div className="sidebar-brandName">
                            Your <span style={{ fontWeight: '700' }}>brand</span> 
                        </div> */}
                    </div>
                    <div className="sidebar-head-sub">
                        <div className="sidebar-heading">
                            โครงการจัดให้มีบริการ
                        </div>
                        <div className="sidebar-subtitle">
                            อินเทอร์เน็ตความเร็วสูง
                            สำหรับคนพิการ
                        </div>
                    </div>
                </div>
                <SidebarImg/>  
            </div>
            {/* <SidebarFooter/> */}
        </div>
    )
}

export default Sidebar